<template>
  <section>
    <v-app>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="6">
            <v-card max-width="300" class="mx-auto">
              <v-card-title>
                Forgot Password
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <label class="caption mx-2">EMAIL ADDRESS</label>
                <v-text-field solo v-model="email" 
                :error-messages="errs.email"/>

                <v-btn @click="reset" block  color="primary">Submit</v-btn>

                <div class="" v-if="success">
                  <v-alert
                    dense
                    text
                    type="success"
                    class="mt-3"
                  >
                    Reset Email Sent!
                  </v-alert> 
                  
                </div>
                <v-btn text class="button" color="info" small link to="/login">
                  Back to login
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    email: '',
    success: false,
    errs: []
  }),
  computed: {
    ...mapState('student', {
      errors: (state) => state.errors
    })
  },
  methods: {
    ...mapActions('student', [
      'resetPassword'
    ]),

    reset() {
      this.resetPassword({
        email: this.email
      }).then(() => {
        if (this.$errors("student")) {
          this.errs = this.errors
          console.log(this.errs);
          return;
        }
        this.email = ''
        this.success = true
      })
    }

  }
}
</script>